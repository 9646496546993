import { group } from '@angular/animations';
import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import html2canvas from 'html2canvas';
import { AccordionTab } from 'primeng/accordion';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { OverlayPanel } from 'primeng/overlaypanel';
import { firstValueFrom } from 'rxjs';
import { ImpactRiskOpportunity } from 'src/app/models/impact-risk-opportunity';
import { Indicator } from 'src/app/models/indicator';
import { ServerService } from 'src/app/services/server.service';
import { numberScale, riskMatrixColors, YesOrNo } from 'src/app/utils';

@Component({
  selector: 'app-dual-materiality',
  templateUrl: './dual-materiality.page.html',
  styleUrls: ['./dual-materiality.page.scss'],
})
export class DualMaterialityPage implements OnInit {
  @ViewChild('op') overlayPanel!: OverlayPanel; // Referencia al Overlay Panel
  @ViewChild('fileUpload') fileUploadRef!: FileUpload;
  dialogBulkEdit: boolean = false;
  bulkToIndividualConfirmed: boolean = false;
  indicatorsByDmId: any[] = [];
  isTabOpen: boolean = false;
  mockIndicator = [{}];
  companyId: any;
  selectedReportGroup: any;
  loadingReport: boolean = false;
  editImpact: boolean = false;
  dialogBulkToIndividualUpdate: boolean = false;
  model: any[] = [];
  loading: boolean = true;
  totales: object = {};
  groupSelected: any;
  dialogDualMateriality: boolean = false;
  dmData: any[] = [];
  matrixGroupData: any;
  groupsWithDmData: any[] = [];
  form1: FormGroup;
  form2: FormGroup;
  form3: FormGroup;
  form4: FormGroup;
  form5: FormGroup;
  form6: FormGroup;
  formImpact: FormGroup;
  active: any = 0;
  loadingModal: boolean = false;
  formDMGroup: FormGroup;
  dialogDualMaterialityForm: boolean = false;
  dialogGroupForm: boolean = false;
  selectedDm: any | null;
  edit: boolean = false;
  dmGroups: any[] = [];
  selectedIndicator: any;
  dialogImpactRisk: boolean = false;
  dialogForm: boolean = false;
  dialogGroup: boolean = false;
  selectedGroup: any | null = null;
  selectedTableGroup: any;
  uploadedFiles: { [step: string]: { [section: string]: File } } = {};
  loadingForm: boolean = false;
  numberScale = numberScale;
  YesOrNo = YesOrNo;
  bulkEdit: boolean = false;
  impacts = [1, 2, 3, 4, 5];
  dialogConfirmationReportDocument: boolean = false;
  dialogDeleteDm: boolean = false;
  dialogDeleteGroup: boolean = false;
  financialRelevances = [1, 2, 3, 4, 5];
  dialogPreviewSubmit: boolean = false;
  loadingModalBtn: boolean = false;
  dialogMatrixClicked: boolean = false;
  indicatorsByDmGroupClicked: any | null = null;
  yesNoOptions = [
    { label: 'Sí', value: true },
    { label: 'No', value: false },
  ];
  riskMatrixColors = riskMatrixColors;
  riskRecords: any[] = [];
  stepperIndex: number = 0;
  existingFiles: {
    [step: number]: { [section: number]: { url: string; name: string } };
  } = {};
  keyMapping: { [mainKey: number]: { [subKey: number]: string } } = {
    1: {
      1: 'regulations_document',
      2: 'business_document',
      3: 'understanding_stakeholders_document',
    },
    2: {
      1: 'internal_consultations_document',
      2: 'external_consultations_document',
    },
    3: {
      1: 'impact_document',
      2: 'financial_relevance_document',
    },
  };
  indicators: Indicator[] = [];
  selectedIndicators: Indicator[] = [];
  dialogRiskMatrix: boolean = false;
  impactRiskOportunity: ImpactRiskOpportunity[] = [];

  upstreamDownstream: any[] = [];
  selectedItemStep5: any;
  selectedFieldStep5: string = '';
  selectedIndexStep5: number = -1;

  financialMateriality: any[] = [];
  selectedItemStep6: any;
  selectedFieldStep6: string = '';
  selectedIndexStep6: number = -1;

  dynamicFormArrayStep7: any[] = [];
  label_field: string = '';
  selectedImpactRegister: ImpactRiskOpportunity | null;

  // Documentos para formulario de impacto
  uploadedFilesImpact: File | null = null;
  uploadedFilesRisk: File | null = null;
  uploadedFilesOpportunity: File | null = null;

  constructor(
    private serverService: ServerService,
    private router: Router,
    private fb: FormBuilder,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.getIndicators();

    this.form1 = this.fb.group({
      regulations_text: [''],
      business_text: [''],
      understanding_stakeholders_text: [''],
    });

    this.form2 = this.fb.group({
      internal_consultations_text: [''],
      external_consultations_text: [''],
    });

    this.form3 = this.fb.group({
      impact: [''],
      impact_text: [''],
      financial_relevance: [''],
      financial_relevance_text: [''],
    });

    this.form4 = this.fb.group({
      up_down_text: [''],
      lca_text: [''],
    });

    this.form5 = this.fb.group({
      transparency_text: [''],
      accordance_text: [''],
    });

    this.formDMGroup = this.fb.group({
      name: ['', Validators.required],
    });

    this.formImpact = this.fb.group({
      impact_form_impact_text: ['', Validators.required],
      impact_form_risk_text: ['', Validators.required],
      impact_form_opportunity_text: ['', Validators.required],
    });

    this.companyId = sessionStorage.getItem('companyId');
    this.getDualMaterialitiesByGroup();
    this.getDMGroups();
    // this.recuperarDatosMenu();
  }

  /**
   * Control de estilo de celdas de tabla
   * @param value
   * @returns
   */
  getColorClass(value: number): string {
    switch (value) {
      case 1:
        return 'low';
      case 2:
        return 'medium-low';
      case 3:
        return 'medium';
      case 4:
        return 'medium-high';
      case 5:
        return 'high';
      default:
        return '';
    }
  }

  /**
   * Mostrar overlaypanel para la tabla paso 5
   * @param event
   * @param overlayPanel
   */
  openOverlayStep5(
    event: MouseEvent,
    field: string,
    item: any,
    index: number
  ): void {
    this.selectedFieldStep5 = field;
    this.selectedItemStep5 = item.upstreamDownstream; // Referencia anidada
    this.selectedIndexStep5 = index;

    if (this.overlayPanel) {
      this.overlayPanel.toggle(event); // Usamos la referencia del ViewChild
    } else {
      console.error('OverlayPanel no encontrado');
    }
  }

  /**
   * Mostrar overlaypanel para la tabla paso 6
   * @param event
   * @param overlayPanel
   */
  openOverlayStep6(
    event: MouseEvent,
    field: string,
    item: any,
    index: number
  ): void {
    this.selectedFieldStep6 = field;
    this.selectedItemStep6 = item.financialMateriality; // Referencia anidada
    this.selectedIndexStep6 = index;

    if (this.overlayPanel) {
      this.overlayPanel.toggle(event);
    } else {
      console.error('OverlayPanel no encontrado');
    }
  }

  /**
   * Actualizar localmente datos de la tabla paso 5
   * @param overlayPanel
   * @param index
   * @param field
   * @param value
   */
  updateValueStep5(
    overlayPanel: OverlayPanel,
    index: number,
    field: string,
    value: any
  ): void {
    this.impactRiskOportunity[index].upstreamDownstream[field] = value;
    overlayPanel.hide();
  }

  /**
   * Actualizar localmente datos de la tabla paso 6
   * @param overlayPanel
   * @param index
   * @param field
   * @param value
   */
  updateValueStep6(
    overlayPanel: OverlayPanel,
    index: number,
    field: string,
    value: any
  ): void {
    // Acceder a 'financialMateriality' y actualizar el campo correspondiente
    this.impactRiskOportunity[index].financialMateriality[field] = value;
    overlayPanel.hide();
  }

  /**
   * Obtener listado de indicadores
   */
  getIndicators() {
    this.serverService
      .getData('/api/getIndicatorsForDualMateriality/')
      .subscribe({
        next: (response) => {
          this.indicators = response.data;
        },
        error: (err) => {
          console.error('Error al obtener datos del menú', err);
        },
      });
  }

  /**
   * Obtener registros de doble materialidad por grupos (estos datos se pintarán en la matriz de riesgos)
   */
  getDualMaterialitiesByGroup() {
    this.serverService.getData(`/api/dual-materialities/byGroup`).subscribe({
      next: (response) => {
        if (response.data) {
          this.groupsWithDmData = response.data;
          this.processDualMaterialityIndicators();
        }
      },
      error: (err) => {
        this.loading = false;
        console.error(
          'Error al obtener los registros de doble materialidad por grupos',
          err
        );
      },
    });
  }

  /**
   * Agrupar los subgrupos de indicadores por cada grupo general
   */
  processDualMaterialityIndicators() {
    if (this.groupsWithDmData) {
      this.groupsWithDmData = this.groupsWithDmData.map((group: any) => {
        const allIndicators = group.dual_materiality.reduce(
          (acc: any[], dm: any) => {
            if (dm.dual_materiality_indicator && dm.dual_materiality_indicator.length > 0) {
              dm.dual_materiality_indicator = dm.dual_materiality_indicator.map((indicator: any) => ({
                ...indicator,
                bulkEdit: dm.dual_materiality_indicator.length > 1 // Campo para edición masiva
              }));
              acc = acc.concat(dm.dual_materiality_indicator);
            }
            return acc;
          },
          []
        );
        
        // Ordenar los indicadores por el campo updated_at
        const sortedIndicators = allIndicators.sort((a: any, b: any) => {
          const dateA = new Date(a.updated_at).getTime();
          const dateB = new Date(b.updated_at).getTime();
          return dateB - dateA; // Ascendente
        });

        return {
          ...group,
          aggregatedIndicators: sortedIndicators, // Agregar el array agrupado al grupo actual
        };
      });
    }
  }

  /**
   * Abrir modal de matriz de riesgos
   */
  openRiskMatrixDialog(group: any) {
    this.dialogRiskMatrix = true;
    this.getRiskMatrixData(group);
  }

  /**
   * Definir estilos en matriz de riesgos
   * @param rowIndex
   * @param colIndex
   * @returns
   */
  getCellClass(rowIndex: number, colIndex: number): string {
    return this.riskMatrixColors[rowIndex][colIndex];
  }

  /**
   * Función que filtra los registros para devolver solo aquellos que coinciden con la frecuencia e impacto dados e imprimirlos en la celda actual
   * @param frequency
   * @param impact
   * @returns
   */
  getRecordsForCell(frequency: string, impact: string): any[] {
    return this.riskRecords.filter(
      (record) => record.frequency === frequency && record.impact === impact
    );
  }

  /**
   * Manejar pulsación de botón para nuevo grupo o doble materialidad para multiples indicadores
   * @param event
   */
  handleAddButton(event) {
    // this.dialogForm = true;
    this.dialogGroup = true;
    this.dialogForm = false;
  }

  /**
   * Manejador de selección de tipo de formulario
   */
  formSelected(type: number) {
    if (type === 1) {
    }

    if (type === 2) {
      this.dialogGroup = true;
      this.dialogForm = false;
    }
  }

  /**
   * Mostrar modal de creación/edición de grupo
   */
  showDialog(group?: any) {
    if (group) {
      this.selectedTableGroup = group;
      this.formDMGroup.patchValue(group);
    }

    this.edit = group ? true : false;
    this.dialogGroup = false;
    this.dialogGroupForm = true;
  }

  /**
   * Obtener grupos de doble materialidad
   */
  async getDMGroups() {
    try {
      const response = await firstValueFrom(
        this.serverService.getData(`/api/dual-materiality/groups`)
      );
      this.dmGroups = response.data ? response.data : [];
      this.loading = false;
    } catch (err) {
      this.loading = false;
      console.error('Error al obtener el listado de grupos', err);
    }
  }

  /**
   * Generador de datos en las celdas para un grupo
   */
  getRiskMatrixData(group: any): void {
    this.matrixGroupData = group;
  
    // Procesamos grupo seleccionado
    this.matrixGroupData.matrixData = {};
  
    this.matrixGroupData['matrixValues'].forEach((item) => {
      // Verificar si el parent_id está definido
      if (item.parent_id) {
        // Obtener las puntuaciones
        const financialRelevance = Math.floor(item.financial_relevance_avg);
        const impact = Math.floor(item.impact_avg);
  
        // Validar que las puntuaciones estén dentro del rango
        if (
          financialRelevance >= 1 &&
          financialRelevance <= 5 &&
          impact >= 1 &&
          impact <= 5
        ) {
          // Si la celda aún no existe, inicializarla
          if (!this.matrixGroupData.matrixData[impact]) {
            this.matrixGroupData.matrixData[impact] = {};
          }
  
          if (!this.matrixGroupData.matrixData[impact][financialRelevance]) {
            this.matrixGroupData.matrixData[impact][financialRelevance] = [];
          }
  
          // Agregar el registro a la celda correspondiente
          this.matrixGroupData.matrixData[impact][financialRelevance].push(item);
        }
      }
    });
  }

  /**
   * Manejar modal para mostrar indicadores de un p-chip pulsado
   */
  showIndicatorsByChip(items) {
    this.indicatorsByDmGroupClicked = items;
    this.dialogMatrixClicked = true;
  }

  /**
   * Recupera los datos del menú desde el servidor según un id de menú dado
   */
  recuperarDatosMenu() {
    this.serverService
      .getData('/api/menusWithIndicatorName/' + this.companyId)
      .subscribe({
        next: (response) => {
          this.model = response.data
            ? response.data['dual_materiality'].map((record) => {
                // Llamamos a la función que verifica si todos los formularios están completos
                const isComplete = this.checkDualMaterialityCompletion(record);

                // Retornamos el registro original con un nuevo campo "isComplete"
                return {
                  ...record,
                  isComplete: isComplete, // Añadimos el campo 'isComplete'
                };
              })
            : [];
          this.loading = true;
        },
        error: (err) => {
          console.error('Error al obtener datos del menú', err);
          this.loading = true;
        },
      });
  }

  /**
   * Validador de formularios completos por separado
   * @param record
   * @returns
   */
  checkDualMaterialityCompletion(record: any): boolean {
    const materialityKeys = [
      'dual_materiality1',
      'dual_materiality2',
      'dual_materiality3',
      'dual_materiality4',
      'dual_materiality5',
    ];

    // Recorremos cada objeto de dual materiality
    for (const key of materialityKeys) {
      const materiality = record[key];
      if (materiality) {
        for (const field in materiality) {
          if (
            !field.includes('hash') &&
            field !== 'created_at' &&
            field !== 'updated_at' &&
            (materiality[field] === null || materiality[field] === '')
          ) {
            return false; // Si algún campo no está cumplimentado, devolvemos false
          }
        }
      }
    }
    return true; // Si todos los campos están cumplimentados, devolvemos true
  }

  /**
   * Manejador de cierre de modal de doble materialidad
   */
  handleHideDualMateriality() {
    this.selectedGroup = null;
    this.selectedIndicators = [];
    this.form1.reset();
    this.form2.reset();
    this.form3.reset();
    this.form4.reset();
    this.form5.reset();
    this.bulkToIndividualConfirmed = false;
    this.bulkEdit = false;
    this.impactRiskOportunity = [];
    
    // Reinicia el índice del stepper
    this.stepperIndex = 0;
  }

  /**
   * Enviar formulario de doble materialidad
   */
  onSubmitForm() {
    if (!this.selectedGroup) {
      return this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Debe seleccionar un grupo de doble materialidad',
      });
    }

    if (!this.edit && this.selectedIndicators.length === 0) {
      return this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Debe seleccionar al menos un indicador',
      });
    }

    const formData = new FormData();
    const allFormData = {
      ...this.form1.value,
      ...this.form2.value,
      ...this.form3.value,
      ...this.form4.value,
      ...this.form5.value,
    };

    // Procesar datos del formulario excluyendo los vacíos
    Object.keys(allFormData).forEach((key) => {
      const value = allFormData[key];
      if (value !== null && value !== '') {
        formData.append(key, value);
      }
    });

    // Procesar los documentos y mapear las claves
    Object.keys(this.uploadedFiles).forEach((mainKey) => {
      Object.keys(this.uploadedFiles[mainKey]).forEach((subKey) => {
        const file = this.uploadedFiles[mainKey][subKey];
        const key = this.keyMapping[Number(mainKey)]?.[Number(subKey)];
        if (key && file) {
          formData.append(key, file);
        }
      });
    });

    this.impactRiskOportunity.forEach((record, index) => {
      Object.keys(record).forEach((key) => {
        const value = record[key];

        // Si el valor es un objeto, agregar sus propiedades individualmente
        if (key === 'upstreamDownstream' || key === 'financialMateriality') {
          Object.keys(value).forEach((subKey) => {
            formData.append(
              `impactRiskOportunity[${index}][${key}][${subKey}]`,
              value[subKey]
            );
          });
        } else {
          // Agregar valores simples directamente
          formData.append(`impactRiskOportunity[${index}][${key}]`, value);
        }
      });
    });

    this.dynamicFormArrayStep7.forEach((record, index) => {
      Object.keys(record).forEach((key) => {
        const value = record[key];
        formData.append(
          `dynamicFormArrayStep7[${index}][${key}]`,
          value !== null ? value : ''
        );
      });
    });

    formData.append('dual_materiality_group_id', this.selectedGroup.id);

    this.loadingForm = true;

    // Edición masiva a individual
    if(this.bulkToIndividualConfirmed){
      this.serverService.sendData(`/api/dual-materialities/dual-materiality-indicator/${this.selectedDm.id}/bulk-to-individual-editing`, formData).subscribe({
        next: (response) => {
          if(response.data){
            this.refreshData();
            this.loadingForm = false;
            this.bulkEdit = false;

            this.messageService.add({
              severity: 'success',
              summary: 'OK',
              detail: 'Indicator actualizado correctamente',
            });
          }
        },
        error: (err) => {
          this.loadingForm = false;
          console.error('Error al editar indicador asociado de forma individual', err);
          this.messageService.add({
            severity: 'warn',
            summary: 'Aviso',
            detail:
              'Ocurrió un error al intentar actualizar el registro, inténtelo de nuevo',
          });
        }
      });
    } else if(this.bulkEdit){ // Si es solo masiva
      const indicatorsId = this.indicatorsByDmId.map(item => item.id);

      // Procesar formulario y los id de formulario
      this.serverService.updatePostData(`/api/dual-materialities/${this.selectedDm.dual_materiality_id}/indicators/${JSON.stringify(indicatorsId)}/bulk-edit`, formData).subscribe({
        next: (response) => {
          if(response.data){
            this.refreshData();
            this.loadingForm = false;
            this.bulkEdit = false;

            this.messageService.add({
              severity: 'success',
              summary: 'OK',
              detail: 'Indicadores actualizados correctamente',
            });
          }
        },
        error: (err) => {
          console.error('Error al actualizar los indicadores de doble materialidad de forma masiva', err);
          this.loadingForm = false;
          this.messageService.add({
            severity: 'warn',
            summary: 'Aviso',
            detail:
              'Ya hay un registro de doble materialidad con este grupo asignado, inténtelo con otro diferente',
          });
        }
      })
    } else { // Es individual
      const request = this.edit
      ? this.serverService.updatePostData(
          `/api/dual-materialities/dual-materiality-indicator/${this.selectedDm.id}`,
          formData
        )
      : this.serverService.sendData(
          `/api/dual-materialities/${JSON.stringify(this.selectedIndicators)}`,
          formData
        );

      request.subscribe({
        next: (response) => {
          if (response.data) {
            this.refreshData();
            this.bulkEdit = false;
            this.messageService.add({
              severity: 'success',
              summary: 'OK',
              detail: 'Registro de doble materialidad guardado correctamente',
            });
          }
          this.loadingForm = false;
          this.dynamicFormArrayStep7 = [];
          this.impactRiskOportunity = [];
        },
        error: (err) => {
          console.error(
            'Error al guardar el registro de doble materialidad',
            err
          );
          if (err.status === 409) {
            this.messageService.add({
              severity: 'warn',
              summary: 'Aviso',
              detail:
                'Ya hay un registro de doble materialidad con este grupo asignado, inténtelo con otro diferente',
            });
          } else {
            this.messageService.add({
              severity: 'warn',
              summary: 'Aviso',
              detail:
                'Error al guardar el registro de doble materialidad, inténtelo de nuevo',
            });
          }
          this.loadingForm = false;
        },
      });
    }
  }

  /**
   * Enviar formulario de grupo de doble materialidad
   */
  async onSubmitGroupForm() {
    this.formDMGroup.markAllAsTouched();

    if (this.formDMGroup.invalid) {
      return this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Revise el formulario',
      });
    }

    const formData = new FormData();
    const formValue = this.formDMGroup.value;

    // Nombre duplicado
    const isDuplicated = this.dmGroups.some(
      (group) =>
        group.name === formValue.name && this.companyId === group.company_id
    );

    if (isDuplicated) {
      return this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Ya existe un grupo con el mismo nombre',
      });
    }

    formData.append('name', formValue.name);
    formData.append('company_id', this.companyId);

    this.loadingForm = true;

    const requestObservable = this.edit
      ? this.serverService.updatePostData(
          `/api/dual-materiality/groups/${this.selectedTableGroup.id}`,
          formData
        )
      : this.serverService.sendData(`/api/dual-materiality/groups`, formData);

    const successDetail = this.edit
      ? 'El grupo de doble materialidad se ha actualizado correctamente'
      : 'El grupo de doble materialidad se ha creado correctamente';

    requestObservable.subscribe({
      next: (response) => {
        if (response.data) {
          this.getDMGroups();
          this.getDualMaterialitiesByGroup();
          this.messageService.add({
            severity: 'success',
            summary: 'OK',
            detail: successDetail,
          });
          this.formDMGroup.reset();
          this.dialogGroupForm = false;
        }
        this.loadingForm = false;
      },
      error: (err) => {
        console.error('Error al enviar formulario', err);
        this.messageService.add({
          severity: 'warn',
          summary: 'Aviso',
          detail: 'Ocurrió un error registrando el grupo, inténtelo de nuevo',
        });
        this.loadingForm = false;
      },
    });
  }

  /**
   * Observador de archivos para el componente p-fileUpload
   */
  getUploadedFiles(step: number, section: number): File[] {
    if (this.uploadedFiles[step] && this.uploadedFiles[step][section]) {
      return [this.uploadedFiles[step][section]];
    }
    return [];
  }

  /**
   * Manejador de subida de archivo
   * @param event
   */
  onFileSelect(event: any, step: number, section: number): void {
    const selectedFiles = event.files;
    if (selectedFiles && selectedFiles.length > 0) {
      const selectedFile = selectedFiles[0];
      if (!this.uploadedFiles[step]) {
        this.uploadedFiles[step] = {};
      }
      this.uploadedFiles[step][section] = selectedFile;
    }
  }

  /**
   * Manejar eliminación de archivo
   * @param event
   */
  deleteFileSelected(event: any, step: number, section: number): void {
    if (this.uploadedFiles[step] && this.uploadedFiles[step][section]) {
      delete this.uploadedFiles[step][section];
    }
  }

  /**
   * Eliminar un grupo de doble materialidad
   */
  deleteGroup(group) {
    this.selectedGroup = group;
    if (this.selectedGroup) {
      this.dialogDeleteGroup = true;
    }
  }

  /**
   * Mostrar modal de formulario de creación/edición de registro de doble materialidad
   */
  async showDualMaterialityFormDialog(group: any, dm?: any) {
    try {
      this.loadingModal = true;
      this.selectedGroup = group;
      this.edit = !!dm;

      if (dm) {
        const response = await firstValueFrom(
          this.serverService.getData(
            `/api/dual-materialities/${dm.dual_materiality_id}/indicator/${dm.indicator_id}`
          )
        );

        if (response?.data) {
          await this.patchForms(response.data);
        }
      }

      this.dialogDualMaterialityForm = true;
      this.dialogDualMateriality = false;
      this.dialogBulkToIndividualUpdate = false;
    } catch (err) {
      console.error(
        'Error al obtener listado de doble materialidad con indicadores',
        err
      );
    } finally {
      this.loadingModal = false; // Asegura que se desactiva siempre.
    }
  }

  /**
   * Procesado de formularios según indicador seleccionado
   */
  async patchForms(dm: any): Promise<void> {
    try {
      this.selectedDm = dm;

      // Step1
      if (dm?.dual_materiality1) {
        this.form1.patchValue({
          regulations_text: dm.dual_materiality1.regulations_text || '',
          business_text: dm.dual_materiality1.business_text || '',
          understanding_stakeholders_text:
            dm.dual_materiality1.understanding_stakeholders_text || '',
        });
      }

      // Step2
      if (dm?.dual_materiality2) {
        this.form2.patchValue({
          internal_consultations_text:
            dm.dual_materiality2.internal_consultations_text || '',
          external_consultations_text:
            dm.dual_materiality2.external_consultations_text || '',
        });
      }

      // Step3
      if (dm?.dual_materiality3) {
        this.form3.patchValue({
          impact: +dm.dual_materiality3.impact,
          impact_text: dm.dual_materiality3.impact_text,
          financial_relevance: +dm.dual_materiality3.financial_relevance,
          financial_relevance_text:
            dm.dual_materiality3.financial_relevance_text,
        });
      }

      // Impactos
      
      if (dm.dual_materiality_impact.length > 0) {
        this.impactRiskOportunity = dm['dual_materiality_impact'].map(
          (item, index) => {
            const dm4 = dm['dual_materiality4'][index];
            const dm5 = dm['dual_materiality5'][index];

            // Función para validar valores numéricos
            const validateValue = (value: any): number | null => {
              const numericValue = Number(value);
              return this.numberScale.some(
                (scale) => scale.value === numericValue
              )
                ? numericValue
                : null;
            };

            let upstreamDownstream: any = null;
            let financialMateriality: any = null;

            if (dm4) {
              upstreamDownstream = {
                id: dm4.id,
                dual_materiality_indicator_id: dm4.dual_materiality_indicator_id,
                magnitude: validateValue(dm4.magnitude),
                irremediability: validateValue(dm4.irremediability),
                material_impact: dm4.material_impact ? true : false,
                scope: validateValue(dm4.scope),
              };
            }

            if (dm5) {
              financialMateriality = {
                id: dm5.id,
                dual_materiality_indicator_id: dm5.dual_materiality_indicator_id,
                magnitude: validateValue(dm5.magnitude),
                irremediability: validateValue(dm5.irremediability),
                material_impact: dm5.material_impact ? true : false,
                scope: validateValue(dm5.scope),
              };
            }

            // Return de valores
            return {
              ...item,
              impact_form_impact_text: item.impact_text,
              impact_form_opportunity_text: item.opportunity_text,
              impact_form_risk_text: item.risk_text,
              financialMateriality: financialMateriality,
              upstreamDownstream: upstreamDownstream,
            };
          }
        );
      }

      // Mapear formulario dinamico
      this.dynamicFormArrayStep7 = dm['dual_materiality6'].map((item) => {
        return {
          ...item,
          label_field: item.label_field,
          value: item.value,
        };
      });

      // Manejar los documentos existentes
      this.populateExistingFiles(dm);

    } catch (err) {
      console.error(
        'Error al procesar los formularios de doble materialidad',
        err
      );
    }
  }

  /**
   * Función para procesar documentos existentes desde el registro de un indicador
   */
  populateExistingFiles(dm: any): void {
    // Inicializar existingFiles
    this.existingFiles = {};

    // Paso 1
    if (dm.dual_materiality1) {
      const step = 1;
      this.existingFiles[step] = {};

      if (dm.dual_materiality1.regulations_document) {
        this.existingFiles[step][1] = {
          url: dm.dual_materiality1.regulations_document,
          name: 'regulations_document.pdf', // Puedes obtener el nombre real si está disponible
        };
      }

      if (dm.dual_materiality1.tendencies_document) {
        this.existingFiles[step][2] = {
          url: dm.dual_materiality1.tendencies_document,
          name: 'tendencies_document.pdf',
        };
      }

      if (dm.dual_materiality1.expectations_document) {
        this.existingFiles[step][3] = {
          url: dm.dual_materiality1.expectations_document,
          name: 'expectations_document.pdf',
        };
      }
    }

    // Paso 2
    if (dm.dual_materiality2) {
      const step = 2;
      this.existingFiles[step] = {};

      if (dm.dual_materiality2.internal_consultations_document) {
        this.existingFiles[step][1] = {
          url: dm.dual_materiality2.internal_consultations_document,
          name: 'internal_consultations_document.pdf',
        };
      }

      if (dm.dual_materiality2.external_consultations_document) {
        this.existingFiles[step][2] = {
          url: dm.dual_materiality2.external_consultations_document,
          name: 'external_consultations_document.pdf',
        };
      }
    }

    // Paso 3
    if (dm.dual_materiality3) {
      const step = 3;
      this.existingFiles[step] = {};

      if (dm.dual_materiality3.impact_document) {
        this.existingFiles[step][1] = {
          url: dm.dual_materiality3.impact_document,
          name: 'impact_document.pdf',
        };
      }

      if (dm.dual_materiality3.financial_relevance_document) {
        this.existingFiles[step][2] = {
          url: dm.dual_materiality3.financial_relevance_document,
          name: 'financial_relevance_document.pdf',
        };
      }
    }

    // Paso 4
    if (dm.dual_materiality4) {
      const step = 4;
      this.existingFiles[step] = {};

      if (dm.dual_materiality4.up_down_document) {
        this.existingFiles[step][1] = {
          url: dm.dual_materiality4.up_down_document,
          name: 'up_down_document.pdf',
        };
      }

      if (dm.dual_materiality4.lca_document) {
        this.existingFiles[step][2] = {
          url: dm.dual_materiality4.lca_document,
          name: 'lca_document.pdf',
        };
      }
    }

    // Paso 5
    if (dm.dual_materiality5) {
      const step = 5;
      this.existingFiles[step] = {};

      if (dm.dual_materiality5.transparency_document) {
        this.existingFiles[step][1] = {
          url: dm.dual_materiality5.transparency_document,
          name: 'transparency_document.pdf',
        };
      }

      if (dm.dual_materiality5.accordance_document) {
        this.existingFiles[step][2] = {
          url: dm.dual_materiality5.accordance_document,
          name: 'accordance_document.pdf',
        };
      }
    }
  }

  /**
   * Eliminar un registro de doble materialidad de un indicador
   */
  deleteDm(dm) {
    this.dialogDeleteDm = true;
    this.selectedDm = dm;
  }

  /**
   * Función auxiliar para selección de eliminación de doble materialidad
   * @param option
   */
  onClickDeleteDm(option: boolean) {
    this.loadingModalBtn = true;
    if (option) {
      this.serverService
        .deleteData(`/api/dual-materialities/dual-materiality-indicator/${this.selectedDm.id}/indicator/${this.selectedDm.indicator_id}`)
        .subscribe({
          next: (response) => {
            if (response.data) {
              this.getDualMaterialitiesByGroup();

              this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail:
                  'Registro de doble materialidad eliminado correctamente',
              });
            }

            this.dialogDeleteDm = false;
            this.loadingModalBtn = false;
            this.selectedDm = null;
          },
          error: (err) => {
            this.loadingModalBtn = false;
            console.error('Error al eliminar el registro', err);
            this.messageService.add({
              severity: 'warn',
              summary: 'Aviso',
              detail:
                'Ocurrió un error al eliminar el registro de doble materialidad, inténtelo de nuevo',
            });
          },
        });
    } else {
      this.dialogDeleteDm = false;
    }
  }

  /**
   * Función auxiliar para selección de eliminación de grupo de doble materialidad
   * @param option
   */
  onClickDeleteGroup(option: boolean) {
    if (option) {
      this.loadingModalBtn = true;
      this.serverService
        .deleteData(`/api/dual-materiality/groups/${this.selectedGroup.id}`)
        .subscribe({
          next: (response) => {
            if (response.data) {
              this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail: 'Grupo de doble materialidad eliminado correctamente',
              });
            }
            this.getDMGroups();
            this.dialogDeleteGroup = false;
            this.loadingModalBtn = false;
            this.selectedGroup = null;
          },
          error: (err) => {
            console.error('Error al eliminar el grupo', err);
            this.messageService.add({
              severity: 'warn',
              summary: 'Aviso',
              detail:
                'Ocurrió un error al eliminar el grupo, inténtelo de nuevo',
            });
          },
        });
    } else {
      this.dialogDeleteGroup = false;
    }
  }

  /**
   * Abrir documento desde formulario
   */
  openDocument(document) {
    if (document.url) {
      window.open(document.url, '_blank');
    } else {
      window.open(document, '_blank');
    }
  }

  /**
   * Actualizar campos del formulario de paso 7
   */
  updateStep7FormFields() {
    if (this.label_field === '') {
      return this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Debe ingresar un título para el campo del formulario',
      });
    }

    const data = {
      label_field: this.label_field,
      value: null,
    };

    this.dynamicFormArrayStep7.push(data);
    this.label_field = '';
  }

  /**
   * Eliminar campo del formulario
   */
  deleteFormField(field: any): void {
    const index = this.dynamicFormArrayStep7.indexOf(field); // Obtengo indice del campo seleccionado con indexOf

    if (index > -1 && this.edit) {
      // API para eliminar este registro
      this.serverService
        .deleteData(
          `/api/dual-materialities/dual-materiality-indicator/${field.dual_materiality_indicator_id}/dual-materiality-6/${field.id}`
        )
        .subscribe({
          next: (response) => {
            if (response.data) {
              this.dynamicFormArrayStep7.splice(index, 1); // Elimino campo concreto
              this.messageService.add({
                severity: 'success',
                summary: 'Éxito',
                detail: 'Campo eliminado correctamente',
              });
            }
          },
          error: (err) => {
            console.error('Error al eliminar el campo', err);
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'No se pudo eliminar el campo',
            });
          },
        });
    } else if (!this.edit && index > -1){
      this.dynamicFormArrayStep7.splice(index, 1); // Elimino campo concreto
    }
  }

  /**
   * Mostrar modal de creación/edición de impacto, riesgo y oportunidad
   */
  showImpactRegisterDialog(item?) {
    if (item) {
      this.selectedImpactRegister = item;
      this.formImpact.patchValue(item);
    }
    this.editImpact = item ? true : false;
    this.dialogImpactRisk = true;
  }

  /**
   * Manejar eliminación de producto
   * @param prouct
   */
  deleteImpact(event, item) {
    console
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      icon: 'pi pi-exclamation-triangle',
      header: 'Confirmar eliminación',
      message: '¿Está seguro de que desea eliminar este registro?',
      acceptLabel: 'Sí',
      rejectLabel: 'No',
      accept: () => {
        this.serverService
          .deleteData(
            `/api/dual-materiality-impact/${item.id}/dual-materiality-indicator/${item.dual_materiality_indicator_id}`
          )
          .subscribe({
            next: (response) => {
              if (response.data) {
                // Elimino localmente el registro correspondiente si la respuest es ok del api
                const index = this.impactRiskOportunity.indexOf(item);

                if (index > -1) {
                  this.impactRiskOportunity.splice(index, 1); // Elimino campo concreto
                  this.messageService.add({
                    severity: 'success',
                    summary: 'Éxito',
                    detail: 'Registro eliminado correctamente',
                  });
                }
              }
            },
            error: (err) => {
              console.error('Error al eliminar el registro', err);
              this.messageService.add({
                severity: 'warn',
                summary: 'Aviso',
                detail:
                  'Ocurrió un error al eliminar el registro, inténtelo de nuevo',
              });
            },
          });
      },
      reject: () => {},
    });
  }

  /**
   * Manejar cierre de modal de impacto
   */
  handleCloseImpactDialog() {
    this.selectedImpactRegister = null;
    this.formImpact.reset();
  }

  /**
   * Manejar guardado local de registro de impactos (se enseña aviso que se guardará en paso 7)
   */
  onPreviewSubmitImpactRegister() {
    if (this.formImpact.invalid) {
      return this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Revise los campos del formulario',
      });
    }

    if (!this.editImpact && !this.edit) {
      // si estoy creando dm e impacto
      this.dialogPreviewSubmit = true;
    } else if (!this.editImpact && this.edit) {
      // si edito un dm y creo un impacto
      const formData = new FormData();
      const formValue = this.formImpact.value;

      formData.append(
        'impact_text',
        formValue.impact_form_impact_text.toString()
      );
      formData.append('risk_text', formValue.impact_form_risk_text.toString());
      formData.append(
        'opportunity_text',
        formValue.impact_form_opportunity_text.toString()
      );
      if (this.uploadedFilesImpact) {
        formData.append('impact_document', this.uploadedFilesImpact);
      }
      if (this.uploadedFilesRisk) {
        formData.append('risk_document', this.uploadedFilesRisk);
      }
      if (this.uploadedFilesOpportunity) {
        formData.append('opportunity_document', this.uploadedFilesOpportunity);
      }

      this.loadingForm = true;

      // si estamos en masiva ==> nuevo impacto a todos los indicadores seleccionados
      if(this.bulkEdit){
        const indicatorsId = this.indicatorsByDmId.map(item => item.id);

        // Creacion masiva de impacto por cada indicador
        this.serverService
        .sendData(
          `/api/dual-materiality-impact/indicators/${JSON.stringify(indicatorsId)}/bulk-impact-store`,
          formData
        ).subscribe({
          next: async (response) => {
            if(response.data){
              // Obtengo actualizado el registro actual de dmIndicator
              const dmIndicatorItem = response.data;

              const dmResponse = await firstValueFrom(
                this.serverService.getData(
                  `/api/dual-materialities/${dmIndicatorItem.dual_materiality_id}/indicator/${dmIndicatorItem.indicator_id}`
                )
              );
      
              if (dmResponse?.data) {
                await this.patchForms(dmResponse?.data);
              }

              this.dialogImpactRisk = false;
              this.uploadedFilesImpact = null;
              this.uploadedFilesRisk = null;
              this.uploadedFilesOpportunity = null;
              this.uploadedFiles = {};
              this.formImpact.reset();
              this.loadingForm = false;
              
              this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail: 'Registro de impacto actualizado correctamente',
              });
            }
          },
          error: (err) => {
            this.loadingForm = false;
            console.error('Error al crear el registro', err);
            this.messageService.add({
              severity: 'warn',
              summary: 'Aviso',
              detail:
                'Ocurrió un error al crear el registro de impacto, inténtelo de nuevo',
            });
          }
        })
      } else { // si es edición normal
        this.serverService
        .sendData(
          `/api/dual-materiality-impact/dual-materiality-indicator/${this.selectedDm.id}`,
          formData
        )
        .subscribe({
          next: async (response) => {
            if (response.data) {
              // Obtengo actualizado el registro actual de dmIndicator
              const dmIndicatorItem = response.data;

              const dmResponse = await firstValueFrom(
                this.serverService.getData(
                  `/api/dual-materialities/${dmIndicatorItem.dual_materiality_id}/indicator/${dmIndicatorItem.indicator_id}`
                )
              );
      
              if (dmResponse?.data) {
                await this.patchForms(dmResponse?.data);
              }

              this.dialogImpactRisk = false;
              this.uploadedFilesImpact = null;
              this.uploadedFilesRisk = null;
              this.uploadedFilesOpportunity = null;
              this.uploadedFiles = {};
              this.formImpact.reset();
              this.loadingForm = false;

              this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail: 'Registro de impacto actualizado correctamente',
              });
            }
          },
          error: (err) => {
            this.loadingForm = false;
            console.error('Error al crear el registro', err);
            this.messageService.add({
              severity: 'warn',
              summary: 'Aviso',
              detail:
                'Ocurrió un error al crear el registro de impacto, inténtelo de nuevo',
            });
          },
        });
      }
    } else if (this.editImpact && !this.bulkEdit) {
      // si solo edito impact
      const formData = new FormData();
      const formValue = this.formImpact.value;

      formData.append(
        'impact_text',
        formValue.impact_form_impact_text.toString()
      );
      formData.append('risk_text', formValue.impact_form_risk_text.toString());
      formData.append(
        'opportunity_text',
        formValue.impact_form_opportunity_text.toString()
      );
      if (this.uploadedFilesImpact) {
        formData.append('impact_document', this.uploadedFilesImpact);
      }
      if (this.uploadedFilesRisk) {
        formData.append('risk_document', this.uploadedFilesRisk);
      }
      if (this.uploadedFilesOpportunity) {
        formData.append('opportunity_document', this.uploadedFilesOpportunity);
      }

      this.loadingForm = true;

      // Procesar y guardar en el servidor
      this.serverService
        .updatePostData(
          `/api/dual-materiality-impact/${this.selectedImpactRegister?.id}/dual-materiality-indicator/${this.selectedImpactRegister?.dual_materiality_indicator_id}`,
          formData
        )
        .subscribe({
          next: (response) => {
            if (response.data) {
              this.dialogImpactRisk = false;
              this.uploadedFilesImpact = null;
              this.uploadedFilesRisk = null;
              this.uploadedFilesOpportunity = null;
              this.uploadedFiles = {};
              this.formImpact.reset();
              this.bulkEdit = false;

              // Actualizar localmente registro
              this.impactRiskOportunity = this.impactRiskOportunity.map(
                (item) => {
                  if (item.id === response.data.id) {
                    return {
                      ...item,
                      impact_form_impact_text: response.data.impact_text,
                      impact_form_opportunity_text:
                        response.data.opportunity_text,
                      impact_form_risk_text: response.data.risk_text,
                    };
                  }
                  return item;
                }
              );

              this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail: 'Registro de impacto actualizado correctamente',
              });

              this.loadingForm = false;
            }
          },
          error: (err) => {
            console.error('Error al actualizar el registro', err);
            this.messageService.add({
              severity: 'warn',
              summary: 'Aviso',
              detail:
                'Ocurrió un error al actualizar el registro de impacto, inténtelo de nuevo',
            });
          },
        });
    } else if (this.editImpact && this.bulkEdit){ // edicion de impacto y masiva
      const indicatorsArray = this.indicatorsByDmId;
      const indicatorsId = this.indicatorsByDmId.map(item => item.id);
      let impactIndex: any;
      
      // Busco indice para cambiar impactos
      const indicatorIndex = indicatorsArray.findIndex(
        (indicator) =>
          indicator['dual_materiality_impact'].length > 0 &&
          indicator['dual_materiality_impact'].some((impact) => impact.id === this.selectedImpactRegister?.id)
      );

      if (indicatorIndex !== -1) {
        // Si encontramos el indicador, buscamos el índice del registro en dual_materiality_impact
        impactIndex = indicatorsArray[indicatorIndex]['dual_materiality_impact'].findIndex(
          (impact) => impact.id === this.selectedImpactRegister?.id
        );
      }

  
      const formData = new FormData();
      const formValue = this.formImpact.value;

      formData.append('update_index', impactIndex.toString());
      formData.append(
        'impact_text',
        formValue.impact_form_impact_text.toString()
      );
      formData.append('risk_text', formValue.impact_form_risk_text.toString());
      formData.append(
        'opportunity_text',
        formValue.impact_form_opportunity_text.toString()
      );

      if (this.uploadedFilesImpact) {
        formData.append('impact_document', this.uploadedFilesImpact);
      }
      if (this.uploadedFilesRisk) {
        formData.append('risk_document', this.uploadedFilesRisk);
      }
      if (this.uploadedFilesOpportunity) {
        formData.append('opportunity_document', this.uploadedFilesOpportunity);
      }

      this.loadingForm = true;

      // Procesar y guardar en el servidor
      this.serverService
        .updatePostData(
          `/api/dual-materiality-impact/dual-materialities/${this.selectedDm.dual_materiality_id}/indicators/${JSON.stringify(indicatorsId)}/bulk-impacts-editing`,
          formData
        )
        .subscribe({
          next: async (response) => {
            if (response.data) {
              // Obtengo actualizado el registro actual de dmIndicator
              const dmIndicatorItem = response.data;

              const dmResponse = await firstValueFrom(
                this.serverService.getData(
                  `/api/dual-materialities/${dmIndicatorItem.dual_materiality_id}/indicator/${dmIndicatorItem.indicator_id}`
                )
              );
      
              if (dmResponse?.data) {
                await this.patchForms(dmResponse?.data);
              }

              this.dialogImpactRisk = false;
              this.uploadedFilesImpact = null;
              this.uploadedFilesRisk = null;
              this.uploadedFilesOpportunity = null;
              this.uploadedFiles = {};
              this.formImpact.reset();

              this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail: 'Registro de impacto actualizado correctamente',
              });

              this.loadingForm = false;
            }
          },
          error: (err) => {
            this.loadingForm = false;
            console.error('Error al actualizar el registro', err);
            this.messageService.add({
              severity: 'warn',
              summary: 'Aviso',
              detail:
                'Ocurrió un error al actualizar el registro de impacto, inténtelo de nuevo',
            });
          },
        });
    }
  }

  /**
   * Manejar cierre de modal de formulario de impacto, riesgo y oportunidad
   */
  closeModalImpactForm() {
    this.dialogPreviewSubmit = false;
    this.dialogImpactRisk = false;

    // Guardado local de registro
    this.impactRiskOportunity.push({
      ...this.formImpact.value,
      impact_form_impact_document: this.uploadedFilesImpact,
      impact_form_risk_document: this.uploadedFilesRisk,
      impact_form_opportunity_document: this.uploadedFilesOpportunity,
      upstreamDownstream: {
        magnitude: null,
        scope: null,
        irremediability: null,
        material_impact: null,
      },
      financialMateriality: {
        magnitude: null,
        scope: null,
        irremediability: null,
        material_impact: null,
      },
    });

    this.formImpact.reset();
    this.uploadedFilesImpact = null;
    this.uploadedFilesRisk = null;
    this.uploadedFilesOpportunity = null;
  }

  /**
   * Manejar documentos seleccionados para formulario de impactos
   * @param event
   * @param type
   * @returns
   */
  onFileSelectImpact(event: any, type: string) {
    let file = event.files[0];
    if (!file) return;

    switch (type) {
      case 'impact':
        this.uploadedFilesImpact = file;
        file = null;
        break;
      case 'risk':
        this.uploadedFilesRisk = file;
        file = null;
        break;
      case 'opportunity':
        this.uploadedFilesOpportunity = file;
        file = null;
        break;
    }
  }

  deleteFileSelectedImpact(type: string) {
    switch (type) {
      case 'impact':
        this.uploadedFilesImpact = null;
        break;
      case 'risk':
        this.uploadedFilesRisk = null;
        break;
      case 'opportunity':
        this.uploadedFilesOpportunity = null;
        break;
    }
  }
  
  /**
   * Función para obtener label de ejes de matriz de riesgo según valor
   */
  getAxisLabel(value: number): string {
    const labelsMap = new Map<number, string>([
      [1, 'Muy bajo'],
      [2, 'Bajo'],
      [3, 'Medio'],
      [4, 'Alto'],
      [5, 'Muy alto'],
    ]);
  
    return labelsMap.get(value) || '';
  }

  /**
   * Modal de aviso para edición masiva
   */
  showBulkEditModal(group, dm){
    this.selectedDm = dm;
    this.dialogBulkEdit = true;
    this.selectedGroup = group;
    this.edit = !!dm;

    // Busco todos los indicadores que pertenecen al seleccionado (dm)
    this.indicatorsByDmId = group['aggregatedIndicators'].filter(item => item.dual_materiality_id === dm.dual_materiality_id);
  }

  /**
   * Carga de datos para editar masivamente
   */
  async onClickBulkEdit(option: boolean){
    if(option){
      this.loadingModal = true;
      this.bulkEdit = true;
      if (this.selectedDm) {
        // Cargo los datos el indicador seleccionado, ya que serán comunes a los que esten asociados a el
        const response = await firstValueFrom(
          this.serverService.getData(
            `/api/dual-materialities/${this.selectedDm.dual_materiality_id}/indicator/${this.selectedDm.indicator_id}`
          )
        );
  
        if (response?.data) {
          await this.patchForms(response.data);
        }
  
        this.dialogBulkEdit = false;
        this.dialogDualMaterialityForm = true;
        this.loadingModal = false;
      }
    } else {
      this.dialogBulkEdit = false;
      this.loadingModal = false;
      this.handleCloseBulkEdit();
    }
  }

  /**
   * Manejar cierre de modal de edición masiva
   */
  handleCloseBulkEdit(){
    this.selectedDm = null;
    this.selectedGroup = null;
    this.form1.reset();
    this.form2.reset();
    this.form3.reset();
    this.form4.reset();
    this.form5.reset();
  }

  /**
   * Manejo para mostrar modal aviso de edición masiva a individual
   */
  showBulkEditToIndividualModal(group, dm){
    this.groupSelected = group;
    this.selectedDm = dm;

    if(dm.bulkEdit){ // Si es masivo y tratar con individual
      this.dialogBulkToIndividualUpdate = true;
    } else {
      this.showDualMaterialityFormDialog(group, dm);
    }
  }

  /**
   * Manejo de modal para confirmar edición masiva a individual
   */
  confirmEditToIndividual(){
    this.bulkToIndividualConfirmed = true;
    this.showDualMaterialityFormDialog(this.groupSelected, this.selectedDm);
  }

  /**
   * Reinicio de varios datos agrupados
   */
  refreshData(){
    this.dialogDualMaterialityForm = false;
    this.getDualMaterialitiesByGroup();
    this.uploadedFilesImpact = null;
    this.uploadedFilesRisk = null;
    this.uploadedFilesOpportunity = null;
    this.uploadedFiles = {};
    this.groupSelected = null;
    this.selectedDm = null;
    this.bulkToIndividualConfirmed = false;
  }

  /**
   * Abrir documento de reporte de doble materialidad según grupo seleccionado
   */
  showReportDocumentConfirmation(group){
    this.getRiskMatrixData(group);
    this.selectedReportGroup = group;
    this.dialogConfirmationReportDocument = true;
  }

  /**
   * Procesado de datos para generar o no reporte de grupo de doble materialidad
   */
  async generateReportDocument(option){
    this.loadingReport = true;

    if(option){
      const formData = new FormData();
      const groupData = this.groupsWithDmData.find(item => item.id === this.selectedReportGroup.id);

      formData.append('matrix_values', JSON.stringify(groupData['matrixValues']));

      if(groupData){
        // Obtener captura de la matriz de riesgos
        const captureElement = document.querySelector('#capture') as HTMLElement;

        if (captureElement == null) {
          console.error('Error durante la captura del elemento:');
        } else {
          try {
            // Esperar a que la imagen se genere correctamente
            const image = await this.generateMatrixImg(captureElement);
    
            if (image) {
              formData.append('risk_matrix_image', image, 'screenshot.png');
            }
          } catch (error) {
            this.loadingReport = false;
            console.error('Error al generar la imagen de la matriz:', error);
          }
        }

        this.serverService.sendData(`/api/dual-materiality/groups/${this.selectedReportGroup.id}/getWord`, formData).subscribe({
          next: (response) => {
            const documentUrl = response.data.report_document;
            window.open(documentUrl, '_blank');
            this.loadingReport = false;
          },
          error: (err) => {
            this.loadingReport = false;
            console.error('Error al generar informe de doble materialidad', err);
          }
        })
      } else {
        this.loadingReport = false;
        return this.messageService.add({
          severity: 'error',
          summary: 'Aviso',
          detail: 'Ocurrió un error con el grupo seleccionado y la posterior descarga del reporte, inténtelo de nuevo',
        })
      }
    } else {
      this.loadingReport = false;
      this.dialogConfirmationReportDocument = false;
    }
  }

  /**
   * Manejo de cierre de modal de confirmación de modal de reporte de doble materialidad
   */
  handleCloseDialogConfirmationReportDocument(){
    this.dialogConfirmationReportDocument = false;
  }

  /**
   * Generar captura de pantalla de matriz
   */
  async generateMatrixImg(captureElement: any) {
    if (captureElement instanceof HTMLElement) {
      try {
        // Obtener canvas mediante html2canvas y excluir elementos específicos
        const canvas = await html2canvas(captureElement, {
          ignoreElements: (element) => {
            return (
              element.classList.contains('vertical-header') || 
              element.classList.contains('horizontal-header')
            );
          }
        });
  
        // Convertir el canvas a Blob
        const blob = await this.toBlobPromise(canvas, 'image/png');
        return blob;
      } catch (error) {
        console.error('Error durante la captura del elemento:', error);
      }
    }
  
    return null;
  }
  async toBlobPromise(
    canvas: HTMLCanvasElement,
    type: string
  ): Promise<Blob | null> {
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error('Failed to convert canvas to Blob'));
        }
      }, type);
    });
  }
}
